<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <img :src="importedImg2" :alt="altText" class="imgItem2" />
      </div>
    </div>

   
    
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/thanhchungplus/components/img/4_1.png";
import importedImg2 from "@/page/thanhchungplus/components/img/4_2.png";


export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
     
   
      altText: "图片",
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}
.imgBox1 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

.imgBox2
{
  display: flex;
  justify-content: center;
  width: 100%;
}
img {
  display: block;
}
.img1 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.img2
 {
  margin-left: 45px;
  margin-right: 45px;
}
.imgItem1 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.imgItem2 {
  max-width: 100%;
  margin-bottom: 100px;
}
/* .imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 100px;
}
.imgItem4 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 100px;
}
.imgItem5 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 100px;
}
.imgItem6 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 100px;
}
.imgItem7 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 150px;
} */
/* .imgItem4 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 100px;
} */
</style>
