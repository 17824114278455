<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <img :src="importedImg2" :alt="altText" class="imgItem2" />
      </div>
    </div>

    <div class="imgBox3 min-w-1400">
      <div class="img3 max-w-1400">
        <img :src="importedImg3" :alt="altText" class="imgItem3" />
      </div>
    </div>
    <div class="imgBox4 min-w-1400">
      <div class="img4 max-w-1400">
        <img :src="importedImg4" :alt="altText" class="imgItem4" />
      </div>
    </div>
    <div class="imgBox5 min-w-1400">
      <div class="img5 max-w-1400">
        <img :src="importedImg5" :alt="altText" class="imgItem5" />
      </div>
    </div>
    <div class="imgBox6 min-w-1400">
      <div class="img6 max-w-1400">
        <img :src="importedImg6" :alt="altText" class="imgItem6" />
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/thanhchungplus/components/img/1_1.png";
import importedImg2 from "@/page/thanhchungplus/components/img/1_2.png";
import importedImg3 from "@/page/thanhchungplus/components/img/1_3.png";
import importedImg4 from "@/page/thanhchungplus/components/img/1_4.png";
import importedImg5 from "@/page/thanhchungplus/components/img/1_5.png";
import importedImg6 from "@/page/thanhchungplus/components/img/1_6.png";
export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      importedImg4: importedImg4,
      importedImg5: importedImg5,
      importedImg6: importedImg6,
      altText: "图片",
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}
.imgBox1 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

.imgBox2,
.imgBox3,.imgBox4,.imgBox5,.imgBox6 {
  display: flex;
  justify-content: center;
  width: 100%;
}
img {
  display: block;
}
.img1 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.img2,
.img3,.img4,.img5,.img6 {
  margin-left: 45px;
  margin-right: 45px;
}
.imgItem1 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.imgItem2 {
  max-width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 100px;
}
.imgItem4 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 100px;
}
.imgItem5 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 140px;
}
.imgItem6 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 150px;
}
/* .imgItem4 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 100px;
} */
</style>
